import React from "react"
import Helmet from "react-helmet"

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {children}
    </div>
  )
}

export default Layout
